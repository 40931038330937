<template>
  <SubNav class="subnav-position" :selected="'comments'" :id="id" />
  <main>
    <div v-if="offeringComments" class="subnav-detail">
      <div v-if="error">{{ error }}</div>
      <div
        v-for="offeringComment in offeringComments"
        :key="offeringComment.id"
      >
        <router-link
          :to="{
            name: 'ReplyComment',
            params: { id: id, commentId: offeringComment.id }
          }"
        >
          <div class="single">
            <div class="d-flex justify-content-between align-items-baseline">
              <div class="name">
                {{ offeringComment.commenterDisplayName }}
              </div>
              <div class="legal" v-if="offeringComment.isHyping">
                *issuer founder, employee, or otherwise compensated to promote
                this offering*
              </div>
              <div class="created-at">
                {{ offeringComment.date }}
              </div>
            </div>
            <div
              class="message"
              :class="{ unread: offeringComment.updatedBy != user.uid }"
            >
              {{ offeringComment.subject }}
            </div>
            <div
              class="message"
              :class="{ unread: offeringComment.updatedBy != user.uid }"
            >
              {{ offeringComment.comment }}
            </div>
            <div class="created-at">
              Reply ({{ offeringComment.replyCount }})
            </div>
            <div class="name" v-if="offeringComment.requestDelete">
              Delete Requested
            </div>
          </div>
        </router-link>
      </div>
      <div
        class="text-center"
        v-if="offeringComments && offeringComments.length == 0"
      >
        <p>
          This is where the comments and questions for your offering will appear
          when an investor posts one, but there are none to show right now.
        </p>
      </div>
    </div>
  </main>
  <Footer v-if="offeringComments" />
</template>

<script>
import SubNav from "@/components/navigation/SubNav.vue";
import getCollection from "@/composables/getCollection";
import getUser from "@/composables/getUser.js";
import Footer from "@/components/navigation/Footer.vue";

export default {
  props: ["id"],
  components: { SubNav, Footer },
  setup(props) {
    const { user } = getUser();

    const { error, documents: offeringComments } = getCollection(
      "offeringComments",
      [["offeringId", "==", props.id]],
      ["updatedAt", "desc"]
    );

    return { error, offeringComments, user };
  }
};
</script>

<style scoped>
.unread {
  color: black !important;
  font-weight: bold !important;
}
.single {
  display: block;
}
</style>